import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './component/Home';
import NotFound from './component/NotFound';
import Whois from './component/Whois';
import IPinfo from './component/IPinfo';
import ServerInfo from './component/ServerInfo';
import PrivacyPolicy from './component/PrivacyPolicy';
import ReCAPTCHAV3 from './ReCAPTCHAV3'; 
import Advertisement from './Advertisement';


function App() {
  return (
    <Router>
      <div>
      <ReCAPTCHAV3/>
      <Helmet>
          <title>ResourcePack unpacker - bypass protection</title>
          <meta name="description" content="Unpack resourcepack, bypass all protection methods provided by tools. Bypass PackSquash, OriginRealms and more!" />
          <link rel="canonical" href="https://resourcedecrypt.cc/" />
      </Helmet>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/whois" element={<Whois />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/IPinfo" element={<IPinfo />} />
          <Route path="/ServerInfo" element={<ServerInfo />} />
        </Routes>
     <Advertisement/>
      </div>
    </Router>
  );
}

export default App;

import React from 'react';
import { Container } from 'react-bootstrap';
import './Home.css'; // Import only relevant CSS if applicable
import './PrivacyPolicy.css'; // Create a dedicated CSS file for styling, optional

function PrivacyPolicy() {
  return (
    <div className="full-height-container">
      <Container className="custom-container text-center bg-dark text-light privacy-policy">
        <h1 className="mb-4" style={{ color: '#007BFF' }}>
          Privacy Policy
        </h1>

        <h2 className="heading">Data Collection</h2>
        <p className="body-text">
          The website collects the minimum amount of personal data necessary for its proper functioning. This data may include:
        </p>
        <ul className="data-list">
          <p>IP address (used for security purposes and identifying broad usage patterns)</p>
          <p>Browser type and operating system (for compatibility and performance optimization)</p>
          <p>Date and time of user activity (for analytics and maintenance purposes)</p>
          <p>Limited anonymized usage data (to understand user behavior and improve the website)</p>
          {/* List other relevant data points if applicable */}
        </ul>

        <h2 className="heading">Data Usage</h2>
        <p className="body-text">
          We use the collected data for the following purposes:
        </p>
        <ul className="data-list">
          <p>Providing and maintaining the functionality of the website</p>
          <p>Analyzing usage patterns to improve the website and user experience</p>
          <p>Detecting and preventing security threats or fraudulent activity</p>
          {/* List other legitimate uses of data */}
        </ul>

        <h2 className="heading">Data Sharing</h2>
        <p className="body-text">
          We do not share personal data with third parties except in the following limited circumstances:
        </p>
        <ul className="data-list">
          <p>As required by law or legal process</p>
          <p>To protect the rights, property, or safety of ourselves or others</p>
          {/* List other exceptional scenarios for data sharing */}
        </ul>

        <h2 className="heading">Data Retention</h2>
        <p className="body-text">
          We retain personal data for as long as it is necessary for the purposes described above, after which it is securely deleted or anonymized.
        </p>

        <h2 className="heading">Your Rights</h2>
        <p className="body-text">
          You have the following rights regarding your personal data:
        </p>
        <ul className="data-list">
          <p>Right to access and rectify your data</p>
          <p>Right to object to the processing of your data</p>
          <p>Right to request erasure of your data</p>
          <p>Right to lodge a complaint with a supervisory authority</p>
          {/* List other relevant user rights */}
        </ul>



      </Container>
    </div>
  );
}

export default PrivacyPolicy;
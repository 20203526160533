import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './NotFound.css';

const NotFound = () => {
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(5); 
  
    useEffect(() => {
      const timer = setTimeout(() => {
        if (countdown > 0) {
          setCountdown(countdown - 1); 
        } else {
          navigate('/');
        }
      }, 1000); 
  
      return () => clearTimeout(timer); 
    }, [countdown, navigate]);

  return (
    <div className="full-height-container">
            <div className="custom-container2 text-center bg-dark text-light">
            <h2 className="not-found-title">404 - Page Not Found</h2>
            <p className="not-found-message">Sorry, the page you are looking for does not exist.</p>
            <p>Redirecting in {countdown} seconds.</p>
            <button className="custom-button" onClick={() => navigate('/')}>Back to home</button>
            </div>
    </div>
  );
}

export default NotFound;

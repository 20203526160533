import React, { useState } from 'react';
import axios from 'axios';
import { Button, Form,Container, Row, Col } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import './Home.css'; 
import './ServerInfo.css'; 

function ServerInfo() {
    const [ip, setIp] = useState('');
    const [gameOption, setGameOption] = useState('');
    const [results, setResults] = useState([]);
  
    const handleIpChange = (e) => {
      setIp(e.target.value);
    };
  
    const handleGameOptionChange = (e) => {
      setGameOption(e.target.value);
    };
  
    const fetchWhoisData = async () => {
      try {
        const response = await axios.get(`http://example.com/whois?ip=${ip}&game=${gameOption}`);
        setResults([{ ip, game: gameOption, data: response.data }]);
      } catch (error) {
        setResults([{ ip, game: gameOption, error: true }]);
      }
    };
  
    return (
      <div className="full-height-container">
        <Container className="custom-container text-center bg-dark text-light">
          <h1 className="mb-4" style={{ color: '#007BFF' }}>Server Game Checker</h1>
          <Row className="justify-content-md-center">
            <Col md={{ span: 6 }}>
              <Form>
                <Form.Group controlId="ipInput" className="mb-3">
                  <Form.Label>Enter IP Address:</Form.Label>
                  <Form.Control type="text" placeholder="Enter IP" className="custom-text-input" onChange={handleIpChange} />
                </Form.Group>
  
                <Form.Group controlId="gameOptionInput" className="mb-3">
                <Form.Label>Select Game:</Form.Label>
                <div className="game-options">
                  <label className="game-option-label">
                    <input
                      type="radio"
                      name="game"
                      value="minecraft"
                      checked={gameOption === 'minecraft'}
                      onChange={handleGameOptionChange}
                    />
                    <span className="checkmark"></span>
                    Minecraft
                  </label>
                  <label className="game-option-label">
                    <input
                      type="radio"
                      name="game"
                      value="fivem"
                      checked={gameOption === 'fivem'}
                      onChange={handleGameOptionChange}
                    />
                    <span className="checkmark"></span>
                    FiveM
                  </label>
                  <label className="game-option-label">
                    <input
                      type="radio"
                      name="game"
                      value="rust"
                      checked={gameOption === 'rust'}
                      onChange={handleGameOptionChange}
                    />
                    <span className="checkmark"></span>
                    Rust
                  </label>
                  <label className="game-option-label">
                    <input
                      type="radio"
                      name="game"
                      value="hurtworld"
                      checked={gameOption === 'hurtworld'}
                      onChange={handleGameOptionChange}
                    />
                    <span className="checkmark"></span>
                    Hurtworld
                  </label>
                </div>
              </Form.Group>
  
                <Button variant="primary" className="custom-button" onClick={fetchWhoisData} disabled={!ip || !gameOption}>
                  Check Server
                </Button>
              </Form>
  
              {results.map((result, index) => (
                <div key={index} className="result-container">
                  <p className="domain-info">IP: {result.ip}</p>
                  <p className="domain-info">Game: {result.game}</p>
                  {result.error ? (
                    <p className="error-message">Error fetching SERVER data</p>
                  ) : (
                    <pre className="whois-data">{JSON.stringify(result.data, null, 2)}</pre>
                  )}
                  <hr className="divider" />
                </div>
              ))}
            </Col>
          </Row>
        </Container>
        <div className="custom-controls">
          {/* ... (reszta kodu dla kontrolek, przycisków itp.) */}
        </div>
      </div>
    );
  }

export default ServerInfo;
import React from "react";
import { useLocation } from "react-router-dom";

const Advertisement = () => {
  const location = useLocation();

  // Wyświetlaj reklamę tylko na określonych stronach
  const shouldShowAd = () => {
    const allowedPaths = ["/", "/Whois", "/about"];
    return allowedPaths.includes(location.pathname);
  };

  return (
    <>
      {shouldShowAd() && (
        <div className="ad-container">
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3551951811041972"
            crossorigin="anonymous"
          />
          <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-3551951811041972"
            data-ad-slot="1234567890"
            data-ad-format="auto"
            data-full-width-responsive="true"
          />
        </div>
      )}
    </>
  );
};

export default Advertisement;
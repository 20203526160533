import React, { useState } from 'react';
import axios from 'axios';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Home.css';
import './Whois.css';

function Whois() {
  const [domain, setDomain] = useState('');
  const [results, setResults] = useState([]);
  const [isValidDomain, setIsValidDomain] = useState(true);

  const handleDomainChange = (e) => {
    const inputDomain = e.target.value;
    setDomain(inputDomain);
    validateDomain(inputDomain);
  };

  const validateDomain = (inputDomain) => {
    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setIsValidDomain(domainRegex.test(inputDomain));
  };

  const fetchWhoisData = async () => {
    try {
      const response = await axios.get(`https://api.resourcedecrypt.cc/whois?domain=${domain}`);
      setResults([{ domain, data: response.data }]);
      toast.success('WHOIS data fetched successfully!');
    } catch (error) {
      setResults([{ domain, error: true }]);
      toast.error('Error fetching WHOIS data');
    }
  };

  return (
    <div className="full-height-container">
      <Container className="custom-container text-center bg-dark text-light">
        <h1 className="mb-4" style={{ color: '#007BFF' }}>
          WHOIS Checker
        </h1>
        <Row className="justify-content-md-center">
          <Col md={{ span: 6 }}>
            <Form>
              <Form.Group controlId="domainInput" className="mb-3">
                <Form.Label>Enter Domain Name:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="example.com"
                  className={`custom-text-input ${isValidDomain ? '' : 'is-invalid'}`}
                  onChange={handleDomainChange}
                />
                {!isValidDomain && (
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid domain name.
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Button variant="primary" className="custom-button" onClick={fetchWhoisData} disabled={!isValidDomain || !domain}>
                Check WHOIS
              </Button>
            </Form>

            {results.map((result, index) => (
              <div key={index} className="result-container">
                <p className="domain-info">Domain: {result.domain}</p>
                {result.error ? (
                  <p className="error-message">Error fetching WHOIS data</p>
                ) : (
                  <pre className="whois-data">{JSON.stringify(result.data, null, 2)}</pre>
                )}
                <hr className="divider" />
              </div>
            ))}
          </Col>
        </Row>
      </Container>
      <div className="custom-controls">{/* ... (reszta kodu dla kontrolek, przycisków itp.) */}</div>
      <ToastContainer position="bottom-right" autoClose={5000}newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss={false} />
    </div>
  );
}

export default Whois;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, ProgressBar, Container, Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Home.css';
import swing from '../assets/swing.ogg';

function Home() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [audio] = useState(new Audio(swing));
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0.060);
  const [showOverlay, setShowOverlay] = useState(false);
  const [fileId, setFileId] = useState('');

  const togglePlay = () => {
    setPlaying(!playing);
    if (!playing) {
      toast.success('Music playing');
    } else {
      toast.error('Music paused');
    }
  };

  const changeVolume = (e) => {
    const newVolume = e.target.value;
    audio.volume = newVolume;
    setVolume(newVolume);
  };

  useEffect(() => {
    audio.volume = volume;
    playing ? audio.play() : audio.pause();
  }, [playing, volume, audio]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, [audio]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const fileNameParts = file.name.split('.');
      const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();

      if (fileExtension !== 'zip') {
        toast.error("Please select a file with the '.zip' extension.");
        setSelectedFile(null);
        return;
      }

      setSelectedFile(file);
    }
  };


  const handleUpload = async () => {
    if (!selectedFile) {
      toast.error("Please select a file first.");
      return;
    }
  
    setUploading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
  
    // Pobierz wartość klucza licencji z formularza
    const licenseKey = document.querySelector('#licenseKey').value;
    formData.append("licenseKey", licenseKey); // Dodaj klucz licencji do formularza
  
    try {
      const response = await axios.post("https://api.resourcedecrypt.cc/upload", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          setUploadProgress(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));
        }
      });
  
      toast.success("File uploaded and decompilation started!");
      setFileId(response.data.fileId);
    } catch (error) {
      if (error.response) {
        toast.error("Upload error: " + error.response.data);
      } else {
        toast.error("Upload error: " + error.message);
      }
    } finally {
      setUploading(false);
      setUploadProgress(0);
    }
  };

  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
};


const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(() => {
      toast.success("Copied to clipboard!");
  }, (err) => {
      toast.error("Failed to copy!");
  });
};


const handleDragOver = (e) => {
  e.preventDefault();
};

const handleDrop = (e) => {
  e.preventDefault();

  const file = e.dataTransfer.files[0];

  if (file) {
    const fileNameParts = file.name.split('.');
    const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();

    if (fileExtension !== 'zip') {
      toast.error("Please drop a file with the '.zip' extension.");
      return;
    }

    setSelectedFile(file);
  }
};




  return (
    <div className="full-height-container">
      <Container className="custom-container text-center bg-dark text-light">
        <h1 className="mb-4" style={{ color: '#007BFF' }}>Upload protected resource pack to unpack for FREE!</h1>
        <Row className="justify-content-md-center">
          <Col md={{ span: 6 }}>
            <Form>
              <Form.Group controlId="formFile" className="mb-3" onDragOver={handleDragOver} onDrop={handleDrop}>
                <Form.Label>Select resource pack</Form.Label>
                <div className="custom-file-upload">
                  <label htmlFor="fileUpload" className="custom-file-label">
                    {selectedFile ? selectedFile.name : 'Choose file'}
                  </label>
                  <input type="file" id="fileUpload" className="custom-file-input" onChange={handleFileChange} disabled={uploading} />
                </div>
              </Form.Group>
              <Form.Group controlId="licenseKey" className="mb-3">
                <Form.Label>License Key</Form.Label>
                <Form.Control type="text" placeholder="Enter license key" className="custom-text-input" />
              </Form.Group>
              <Button
                  variant="primary"
                  className="custom-button"
                  onClick={() => {
                    if (uploading) {
                      return;
                    }
                    fileId ? window.location.href = `https://api.resourcedecrypt.cc/download/${fileId}` : handleUpload();
                  }}
                  disabled={uploading || !selectedFile}
                >
                  {uploading ? 'Uploading...' : fileId ? 'Download file' : 'Upload'}
            </Button>


            </Form>
            {uploadProgress > 0 && (
              <ProgressBar className="custom-progress-bar">
                <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
              </ProgressBar>
            )}
            <p className="mt-3" style={{ color: '#949494' }}>
              We bypass all protection methods for minecraft resource packs - freedom above all<br />
              We are not responsible for what users upload - files are deleted every 2 hours.
            </p>
            <p className="mt-3" style={{ color: '#949494' }}>
              The free version allows you to unzip files up to 10 MB.
              Purchase a license to enjoy unzipping files up to 50 MB..
            </p>
            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
          </Col>
        </Row>
      </Container>
      <div className="custom-controls">
        <div className="audio-controls">
          <button onClick={togglePlay} className="audio-control-button">
            {playing ? 'Pause Music' : 'Play Music'}
          </button>
          <input type="range" min="0" max="1" step="0.01" value={volume} onChange={changeVolume} className="volume-slider" />
        </div>
        <div className="extra-buttons">
          <button
            data-sellix-product="65c9216b361b5"
            type="submit"
            alt="Buy Now with sellix.io"
            className="custom-button"
          >
            Purchase
          </button>
          <Button variant="info" className="custom-button" onClick={toggleOverlay}>Donate</Button>


          {showOverlay && (
            <div className="overlay">
                <div className="overlay-content">
                    <h1 className="mb-4" style={{ color: '#007BFF' }}>Donate Crypto</h1>
                    <div  className="mt-3" style={{ color: '#949494' }}> BITCOIN: 16k1coQiea6ZekGbu65khkQUwgbeytuDAv <button onClick={() => copyToClipboard('16k1coQiea6ZekGbu65khkQUwgbeytuDAv')}>Copy</button></div>
                    <div  className="mt-3" style={{ color: '#949494' }}> ETHEREUM [ERC20]: 0x6d89262064454f2059887f029731cea9ba7845cf <button onClick={() => copyToClipboard('0x6d89262064454f2059887f029731cea9ba7845cf')}>Copy</button></div>
                    <div  className="mt-3" style={{ color: '#949494' }}> USDT [TRC20]: TKdycCmz7GecH354dV6cVi2CwSig5qgNQP <button onClick={() => copyToClipboard('TKdycCmz7GecH354dV6cVi2CwSig5qgNQP')}>Copy</button></div>
                    <div  className="mt-3" style={{ color: '#949494' }}> LITECOIN: La3186VS1wDpTJdjSRymR1MBVSPGzdr22J <button onClick={() => copyToClipboard('La3186VS1wDpTJdjSRymR1MBVSPGzdr22J')}>Copy</button></div>
                    <button onClick={toggleOverlay}>Close</button>
                </div>
            </div>
         )}


          <Button variant="primary" className="custom-button" onClick={() => window.open('https://discord.gg/b2AUPaj')}>
           Discord
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Home;
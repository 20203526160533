import React, { useState } from 'react';
import axios from 'axios';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import './Home.css';
import './Whois.css';

function IPinfo() {
  const [ipAddress, setIpAddress] = useState('');
  const [results, setResults] = useState([]);

  const handleIPChange = (e) => {
    setIpAddress(e.target.value);
  };

  const isValidIPv4 = (ip) => {
    // Simple regex to validate IPv4 address
    const ipv4Regex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
    return ipv4Regex.test(ip);
  };

  const fetchDNSData = async () => {
    try {
      if (!isValidIPv4(ipAddress)) {
        // Display an error message or handle invalid IPv4 address
        return;
      }

      const response = await axios.get(`http://example.com/dns?ip=${ipAddress}`);
      setResults([{ ipAddress, data: response.data }]);
    } catch (error) {
      setResults([{ ipAddress, error: true }]);
    }
  };

  return (
    <div className="full-height-container">
      <Container className="custom-container text-center bg-dark text-light">
        <h1 className="mb-4" style={{ color: '#007BFF' }}>
          IP DNS Checker
        </h1>
        <Row className="justify-content-md-center">
          <Col md={{ span: 6 }}>
            <Form>
              <Form.Group controlId="ipInput" className="mb-3">
                <Form.Label>Enter IPv4 address:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter IPv4 address"
                  className="custom-text-input"
                  onChange={handleIPChange}
                />
              </Form.Group>
              <Button variant="primary" className="custom-button" onClick={fetchDNSData} disabled={!isValidIPv4(ipAddress)}>
                Check DNS
              </Button>
            </Form>

            {results.map((result, index) => (
              <div key={index} className="result-container">
                <p className="domain-info">IP DNS: {result.ipAddress}</p>
                {result.error ? (
                  <p className="error-message">Error fetching DNS data</p>
                ) : (
                  <pre className="dns-data">{JSON.stringify(result.data, null, 2)}</pre>
                )}
                <hr className="divider" />
              </div>
            ))}
          </Col>
        </Row>
      </Container>
      <div className="custom-controls">{/* ... (reszta kodu dla kontrolek, przycisków itp.) */}</div>
    </div>
  );
}

export default IPinfo;